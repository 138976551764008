<template>
  <el-dialog
    :title="$t('linkOrder')"
    :visible.sync="dialogVisible"
    width="80%"
    top="12vh"
    @close="$emit('update:visible', false)">
    <div class="search-block">
      <div class="search-item" style="width:305px;">
        <el-input
          class="round-input"
          placeholder="Search Order"
          v-model="searchKey"
          @keypress.enter.native="handlerSearchKey">
          <el-button slot="append" icon="el-icon-search" @click="handlerSearchKey"></el-button>
        </el-input>
      </div>
      <!-- <el-button
        round
        style="margin-left:10px;"
        @click="handlerSearchKey"
      >{{ $t('search') }}<em class="el-icon-search el-icon--right"></em></el-button> -->
    </div>
    <div class="table-block">
      <el-table
        ref="singleTable"
        v-loading="tableLoading"
        :data="tableData"
        height="45vh"
        highlight-current-row
        @row-click="handleCurrentChange"
        stripe>
        <el-table-column
          width="55">
          <div class="check-circle">
            <em class="el-icon-check check-icon"></em>
          </div>
        </el-table-column>
        <el-table-column
          v-if="currentCompany.companyName"
          prop="orderNoRelative"
          :label="$t('orderNo')"
          width="280">
        </el-table-column>
        <el-table-column
          v-else
          :label="$t('orderNo')"
          width="280">
          <template slot-scope="scope">
            <div>{{scope.row.purchaseOrderNumber}}</div>
            <div>{{scope.row.salesOrderNumber}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderMode"
          :label="$t('orderMode')"
          width="110">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.orderMode==='term'?'danger':'primary'">
              {{ scope.row.orderMode }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="tradeType"
          :label="$t('orderType')"
          width="120">
        </el-table-column>
        <el-table-column
          label="Order Status"
          show-overflow-tooltip
          width="200">
          <template slot-scope="scope">
            <div class="flex-row">
              <em
                :style="{color: orderStatusColor[orderStatusDescValues[scope.row.state].key]}"
                class="iconfont icon-yuandianzhong">
              </em>
              <span style="font-weight:bolder;">{{orderStatusDescValues[scope.row.state].value}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="buyerCompanyName"
          label="Buyer"
          show-overflow-tooltip
          width="260">
        </el-table-column>
        <el-table-column
          prop="sellerCompanyName"
          label="Seller"
          show-overflow-tooltip
          width="260">
        </el-table-column>
        <el-table-column
          prop="terminal"
          :label="orderType === 'Ex-Wharf' ? 'Terminal' : 'Vessel'"
          show-overflow-tooltip
          width="180">
        </el-table-column>
        <el-table-column
          :label="(orderType === 'Ex-Wharf' ? 'Loading Dates' : 'ETA Range') + ' (From-To)'"
          show-overflow-tooltip
          width="250">
          <template slot-scope="scope">
            <div>
              <span
                class="title-font-small"></span>{{ scope.row[liftingDetail] && scope.row[liftingDetail].dateRangeStart ? $moment(scope.row[liftingDetail].dateRangeStart).format('DD-MM-YYYY HH:mm') : '' }}
            </div>
            <div>
              <span
                class="title-font-small"></span>{{ scope.row[liftingDetail] && scope.row[liftingDetail].dateRangeEnd ? $moment(scope.row[liftingDetail].dateRangeEnd).format('DD-MM-YYYY HH:mm') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productGroup"
          label="Product/Grade/Specs"
          width="420">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.productGroup"
              :key="index">
              <el-tag
                type="info"
                size="small">
                {{ item }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="orderType === 'Ex-Wharf' ? 'Loading Quantity' : 'Delivered Quantity'"
          show-overflow-tooltip
          align="center"
          width="160">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.products"
              :key="index">
              {{item.finalQty}}mt
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="orderType !== 'Ex-Wharf'"
          label="Physical Supplier"
          width="180">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.products || []"
              :key="index">
              <el-select
                class="no-bg-select"
                disabled
                :value="item.physicalSupplierBdn"
                placeholder="">
                <el-option
                  v-for="company in companyOpts"
                  :key="company.value"
                  :label="company.label"
                  :value="company.licenseNumber">
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentPageChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :current-page="pageIndex"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <span class="dialog-footer-center">
      <el-button round type="primary" @click="handlerConfirm" style="padding: 12px 45px;">{{
          $t('confirm')
        }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { orderStatusDesc, orderStatusColor } from '@/config.js'
import { mapState } from 'vuex'

export default {
  mixins: [baseMixin, baseTableMixin],
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    orderMode: {
      type: String,
      default: () => ''
    },
    order: {
      type: Object,
      default: () => {}
    },
    initialData: null
  },
  data () {
    return {
      orderStatusDescValues: Object.keys(orderStatusDesc).map(item => ({
        key: item,
        value: orderStatusDesc[item]
      })),
      orderStatusColor,
      queryUrl: '',
      dialogVisible: this.visible,
      searchKey: '',
      selectData: {},
      orderType: '',
      companyList: []
    }
  },
  computed: {
    ...mapState({
      currentCompany: 'currentCompany'
    }),
    liftingDetail () {
      const mapping = {
        'Ex-Wharf': 'exwharfLiftingDetail',
        Delivered: 'deliveredLiftingDetail'
      }
      return mapping[this.orderType]
    },
    companyOpts () {
      return this.companyList.map(item => ({
        value: item.id,
        label: item.companyName,
        licenseNumber: item.licenseNumber
      }))
    }
  },
  watch: {
    visible () {
      this.dialogVisible = this.visible
      if (this.visible) {
        this.queryUrl = this.$apis.queryContract
        this.initCondition()
        this.selectData = this.initialData ? { ...this.initialData } : {}
        this.getCompanyList()
        this.getTableData()
      }
    }
  },
  methods: {
    getTableData () {
      this.pageSize = 100
      this.getTableDataMixin().then(() => {
        this.tableData = this.tableData.map(item => Object.assign({}, item, {
          // deliveryRange: `${item.dateRangeStart || ''} - ${item.dataRangeEnd || ''}`,
          productGroup: item.products ? item.products.map(pro => `${pro.productName} / ${pro.specs}`) : [],
          tradeType: item.buyerCompanyName === this.currentCompany.companyName ? 'Purchase' : 'Sales',
          terminal: this.orderType === 'Ex-Wharf' ? item[this.liftingDetail]?.terminal : item[this.liftingDetail]?.vessel,
          orderNoRelative: item.buyerCompanyName === this.currentCompany.companyName ? item.purchaseOrderNumber : item.salesOrderNumber
        }))
        if (this.order.id) {
          this.tableData = this.tableData.filter(item => item.id !== this.order.id)
          this.tableData = this.orderMode === 'term' ? this.tableData.filter(item => item.tradeType === this.order.tradeType) : this.tableData
        }
        if (this.initialData) {
          const row = this.tableData.find(item => item.id === this.initialData.id)
          this.setCurrent(row)
        }
      })
    },
    handleCurrentPageChange (page) {
      this.pageIndex = page
      this.getTableData()
    },

    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageIndex = 1
      this.getTableData()
    },
    initCondition () {
      this.queryCondition = Object.assign({}, this.queryCondition, {
        keyword: '',
        keywordCondition: {}
      })
      this.otherParams = Object.assign({}, this.otherParams, {
        orderMode: this.orderMode,
        orderType: this.orderType.toLowerCase()
      })
    },
    handlerConfirm () {
      if (this.selectData?.id) {
        this.$emit('getValue', this.selectData)
      } else {
        this.$emit('getValue', null)
      }
      this.dialogVisible = false
    },
    // handleCurrentChange (val) {
    //   this.selectData = val
    // },
    handleCurrentChange (row) {
      if (this.selectData.id === row.id) {
        this.$refs.singleTable.setCurrentRow({})
        this.selectData = {}
        return false
      }
      this.$refs.singleTable.setCurrentRow(row)
      this.selectData = row
    },
    handlerSearchKey () {
      this.otherParams = {}
      this.queryCondition = Object.assign({}, this.queryCondition, {
        keyword: this.searchKey,
        keywordCondition: {
          orderMode: this.orderMode,
          orderType: this.orderType.toLowerCase()
        }
      })
      if (!this.searchKey) this.initCondition()
      this.getTableData()
    },
    setCurrent (row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
    getCompanyList () {
      /**
       * 获取企业列表
       */
      this.$request.get({
        url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999`
      }).then(res => {
        if (res?.code === 1000) {
          this.companyList = res.data?.records ?? []
        }
      })
    }
  },
  mounted () {
    this.orderType = this.$route?.name
  }

}

</script>

<style lang='scss' scoped>
/deep/ .el-dialog {
  max-width: 1976px;

  .el-dialog__title {
    font-weight: 700;
    font-size: 20px;
  }
}

.search-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;

  .search-item {
    width: 30vw;
  }
}

.check-circle {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .check-icon {
    color: #fff;
    font-size: 14px;
  }
}

/deep/ .el-table__body tr.current-row > td .check-circle {
  background-color: #0F376A;
  border: 1px solid #0F376A;
}

/deep/ .el-table__body tr > td {
  cursor: pointer;
}
</style>
